<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-row>
          <v-col cols="9" md="11"
            ><v-card-title
              >{{$t("salesDiningPage.dailyDiningSales")}}  <span>({{ date }})</span></v-card-title
            ></v-col
          >
          <v-col cols="3" md="1">
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="mt-3" icon>
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <v-date-picker v-model="date" type="month" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$refs.dialog.save(date)">
                  {{$t("salesSummaryPage.choose")}} 
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="5" md="3" class="ml-4">
            <v-select
              class="mt-3"
              v-model="selectBranch"
              :items="branchList"
              item-text="name"
              item-value="branch_id"
              outlined
              dense
            ></v-select
          ></v-col>
        </v-row>
        <v-row>
          <!-- <v-col>
              <div v-show="showChart">
                <apexchart :options="chartOptions" :series="series" />
              </div>
            </v-col> -->
          <v-col cols="12">
            <base-card>
              <div class="d-flex align-center justify-space-between pr-3">
                <!-- <v-card-title>Daily Sales Table</v-card-title> -->
                <v-spacer></v-spacer>
                <div v-if="salesList.length != 0">
                  <export-excel
                    :data="salesList"
                    :fields="json_fields"
                    :name="filename"
                  >
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"> mdi-download </v-icon>
                      </template>
                      <span>{{$t("salesDiningPage.downloadDailyDiningReport")}}</span>
                    </v-tooltip>
                  </export-excel>
                </div>
              </div>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="salesList"
                  :page.sync="page"
                  :no-data-text='$t("dailyModifierSalesPage.noData")'
                  :items-per-page="itemsPerPage"
                  @page-count="pageCount = $event"
                  item-key="sales_id"
                  hide-default-footer
                >
                </v-data-table>
                <div class="text-center py-2">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                  ></v-pagination>
                </div>
              </v-card-text>
            </base-card>
          </v-col>
        </v-row>
      </base-card>
    </v-col>
    <!-- <v-col cols="12">
        <base-card>
          <div class="d-flex align-center justify-space-between pr-3">
            <v-card-title>Daily Sales Table</v-card-title>
            <v-spacer></v-spacer>
            <div v-if="salesList.length != 0">
              <export-excel
                :data="salesList"
                :fields="json_fields"
                type="csv"
                :name="filename"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"> mdi-download </v-icon>
                  </template>
                  <span>Download Daily Sales Report</span>
                </v-tooltip>
              </export-excel>
            </div>
          </div>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="salesList"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              item-key="sales_id"
              hide-default-footer
            >
            </v-data-table>
            <div class="text-center py-2">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </div>
          </v-card-text>
        </base-card>
      </v-col> -->
  </v-row>
</template>
    <script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import TestCard from "@/components/card/TestCard";
import PriceCard from "@/components/card/PriceCard";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Sales",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Sales",
  },
  components: {
    "test-card": TestCard,
    "price-card": PriceCard,
    VueHtml2pdf,
  },
  data() {
    return {
      json_fields: {
        id: "id",
        Branch: "name",
        "Dine in": "dine_in",
        "Take away": "take_away",
        "Delivery": "delivery",
        Date: "date",
      },
      domain: BASEURL,
      company_id: "",
      showIcon: false,
      showChart: false,
      date: "",
      modal: false,
      branchList: [],
      salesList: [],
      selectBranch: "",
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },

        {
          text: "Branch",
          align: "start",
          sortable: false,
          value: "name",
        },

        {
          text: "Dine In",
          align: "start",
          sortable: false,
          value: "dine_in",
        },

        {
          text: "Take Away",
          align: "start",
          value: "take_away",
          sortable: false,
        },

        {
          text: "Delivery",
          align: "start",
          value: "delivery",
          sortable: false,
        },

        {
          text: "Date",
          align: "start",
          value: "date",
          sortable: true,
        },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,

      //chart
      series: [
        {
          name: "Sales",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 300,
          width: 600,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "Date/Month/Year",
        },
      },
    };
  },
  computed: {
    filename() {
      return "Daily Dining Sale Report_" + this.date + ".xls";
    },
  },
  watch: {
    date() {
      this.salesList = [];
      this.getAllSettlement();
    },
    selectBranch() {
      this.salesList = [];
      this.getAllSettlement();
    },
  },
  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getCompanyBranch();
  },
  methods: {
    getAllSettlement() {
      const params = new URLSearchParams();
      params.append("getDiningPerDayList", "done");
      params.append("branch_id", this.selectBranch);
      params.append("month", this.date);

      axios({
        method: "post",
        url: this.domain + "/report/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);

          if (response.data.status == "1") {
            // this.series = [
            //   {
            //     name: "Sales",
            //     data: [],
            //   },
            // ];
            // var moment = require("moment");
            this.salesList = response.data.report;
            // this.showChart = true;

            // var currentMonthDates = Array(
            //   moment(this.date, "YYYY-MM").daysInMonth()
            // )
            //   .fill(null)
            //   .map((x, i) => moment(this.date).startOf("month").add(i, "days"));

            // for (let i = 0; i < currentMonthDates.length; i++) {
            //   var obj = { x: "", y: "" };
            //   obj.x = moment(currentMonthDates[i]).format("DD-MM-YYYY");
            //   obj.y = 0.0;
            //   this.series[0].data.push(obj);
            // }

            // for (let j = 0; j < this.salesList.length; j++) {
            //   const index = this.series[0].data.findIndex((obj) => {
            //     return (
            //       obj.x === moment(this.salesList[j].date).format("DD-MM-YYYY")
            //     );
            //   });
            //   this.series[0].data[index].x = moment(
            //     this.salesList[j].date
            //   ).format("DD-MM-YYYY");
            //   this.series[0].data[index].y = this.salesList[j].total_amount;
            // }
          } else {
            // this.series = [
            //   {
            //     name: "Sales",
            //     data: [],
            //   },
            // ];
            this.salesList = [];
            // var moment = require("moment");
            // var currentMonthDates = new Array(
            //   moment(this.date, "YYYY-MM").daysInMonth()
            // )
            //   .fill(null)
            //   .map((x, i) =>
            //     moment(this.date, "YYYY-MM").startOf("month").add(i, "days")
            //   );

            // for (let w = 0; w < currentMonthDates.length; w++) {
            //   var obj = { x: "", y: "" };
            //   obj.x = moment(currentMonthDates[w]).format("DD-MM-YYYY");
            //   obj.y = 0.0;
            //   this.series[0].data.push(obj);
            // }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var moment = require("moment");
            this.branchList = response.data.branch;
            this.selectBranch = this.branchList[0].branch_id;
            this.date = moment().format("YYYY-MM");
            this.getAllSettlement();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
    <style>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: large;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
</style>
    
    
    